.slide {
  position: relative;
  
}

.text-overlay {
  position: absolute;
  top: 40%;
  left: 75%;
  transform: translate(-50%, -50%);
  /* background-color: rgba(0, 0, 0, 0.5);  */
  color: #fff; /* Text color */
  padding: 10px;
  text-align: center;
  max-width: 80%; /* Adjust as needed */
 
}

.text-overlay h2{ 
  font-size: 96px;
}

.text-overlay .new-comings{
  letter-spacing: 8px;
}

.slide .slide-img {
  max-width: none;
}

/* Default height */
.hero-slider {
height: 650px; /* Adjust this value based on your design */
}

/* Increase the height when the page width is below a certain breakpoint */
@media (max-width: 1220px) {
.hero-slider {
  height: 600px; /* Adjust this value for the increased height */
}

.text-overlay {
  display: none;
  /* background-color: aqua;
  position: absolute;
  top: 40%;
  left: 75%;
  transform: translate(-50%, -50%); */
  /* background-color: rgba(0, 0, 0, 0.5);  */
  /* color: #fff; Text color */
  /* padding: 10px;
  text-align: center;
  max-width: 80%; Adjust as needed */
}

.text-overlay h2{ 
  font-size: 70px;
}

}


/* Increase the height when the page width is below a certain breakpoint */
@media (max-width: 790px) {
.hero-slider {
  height: 300px; /* Adjust this value for the increased height */
}

.text-overlay {
  display: none;
  /* background-color: aqua;
  position: absolute;
  top: 40%;
  left: 45%;
  transform: translate(-50%, -50%); */
  /* background-color: rgba(0, 0, 0, 0.5);  */
  /* color: #fff; Text color */
  /* padding: 10px;
  text-align: center;
  max-width: 80%; Adjust as needed */
}

.text-overlay h2{ 
  font-size: 50px;
}

}

/* Further adjustments for smaller screens if needed */
@media (max-width: 430px) {
.text-overlay {
  display: none;
  /* position: absolute;
  top: 50%;
  left: 48%;
  transform: translate(-50%, -50%); */
  /* background-color: rgba(0, 0, 0, 0.5);  */
  /*color: #fff; /* Text color */
  /* padding: 10px;
  text-align: center; */
 /* max-width: 80%; /* Adjust as needed */
}

.slide{
  background-color: aqua;
}
.text-overlay{
  background-color: #f59e0b;
}
.text-overlay h2{ 
  font-size: 50px;
}

.hero-slider {
  height: 200px; /* Adjust this value for the increased height */
}

.slide .slide-img {
 contain: content;
}
}
